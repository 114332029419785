import { useTranslation } from "react-i18next";
import "./PrivacyPolicy.css"; // Asegúrate de importar el archivo CSS

const PrivacyPolicyContent = () => {
  const { t } = useTranslation();
  return (
    <div className="privacy-policy-container">
      <h1>{t("Política de Privacidad")}</h1>
      <p>{t("Última actualización: 14 de agosto de 2024")}</p>

      <h2>{t("Interpretación y Definiciones")}</h2>

      <h3>{t("Interpretación")}</h3>
      <p>{t("Las palabras cuya letra inicial está en mayúscula tienen significados definidos bajo las siguientes condiciones. Las siguientes definiciones tendrán el mismo significado independientemente de si aparecen en singular o en plural.")}</p>

      <h3>{t("Definiciones")}</h3>
      <p>{t("A los efectos de esta Política de Privacidad:")}</p>
      <ul>
        <li>{t("Cuenta significa una cuenta única creada para que usted acceda a nuestro Servicio o a partes de nuestro Servicio.")}</li>
        <li>{t("Afiliado significa una entidad que controla, es controlada por o está bajo control común con una parte, donde 'control' significa la propiedad del 50% o más de las acciones, participación accionaria u otros valores con derecho a voto para la elección de directores u otra autoridad de gestión.")}</li>
        <li>{t("Empresa se refiere al Congreso Espacial Centroamericano 2024.")}</li>
        <li>{t("Cookies son pequeños archivos que se colocan en su computadora, dispositivo móvil o cualquier otro dispositivo por un sitio web, que contienen los detalles de su historial de navegación en ese sitio web, entre otros usos.")}</li>
        <li>{t("País se refiere a Guatemala.")}</li>
        <li>{t("Dispositivo significa cualquier dispositivo que pueda acceder al Servicio, como una computadora, un teléfono móvil o una tableta digital.")}</li>
        <li>{t("Datos Personales es cualquier información que se relaciona con un individuo identificado o identificable.")}</li>
        <li>{t("Servicio se refiere al Sitio Web.")}</li>
        <li>{t("Proveedor de Servicios significa cualquier persona física o jurídica que procesa los datos en nombre de la Empresa.")}</li>
        <li>{t("Servicio de Redes Sociales de Terceros se refiere a cualquier sitio web o sitio de red social a través del cual un Usuario puede iniciar sesión o crear una cuenta para utilizar el Servicio.")}</li>
        <li>{t("Datos de Uso se refiere a los datos recopilados automáticamente, ya sea generados por el uso del Servicio o de la propia infraestructura del Servicio (por ejemplo, la duración de una visita a la página).")}</li>
      </ul>

      <h2>{t("Recopilación y uso de sus datos personales")}</h2>

      <h3>{t("Tipos de datos recopilados")}</h3>

      <h4>{t("Datos Personales")}</h4>
      <p>{t("Mientras utiliza nuestro Servicio, podemos pedirle que nos proporcione cierta información de identificación personal que se puede utilizar para contactarlo o identificarlo. La información de identificación personal puede incluir, entre otros:")}</p>
      <ul>
        <li>{t("Dirección de correo electrónico")}</li>
        <li>{t("Nombre y apellido")}</li>
        <li>{t("Datos de uso")}</li>
      </ul>

      <h4>{t("Datos de Uso")}</h4>
      <p>{t("Los Datos de Uso se recopilan automáticamente cuando se utiliza el Servicio.")}</p>
      <p>{t("Los Datos de Uso pueden incluir información como la dirección del Protocolo de Internet de su dispositivo (por ejemplo, la dirección IP), el tipo de navegador, la versión del navegador, las páginas de nuestro Servicio que visita, la hora y la fecha de su visita, el tiempo que pasa en esas páginas, identificadores únicos de dispositivos y otros datos de diagnóstico.")}</p>

      <h2>{t("Información de Servicios de Redes Sociales de Terceros")}</h2>
      <p>{t("La Empresa le permite crear una cuenta e iniciar sesión para utilizar el Servicio a través de los siguientes Servicios de Redes Sociales de Terceros:")}</p>
      <ul>
        <li>{t("Google")}</li>
        <li>{t("Facebook")}</li>
        <li>{t("Instagram")}</li>
        <li>{t("Twitter")}</li>
        <li>{t("LinkedIn")}</li>
      </ul>
      <p>{t("Si decide registrarse a través de o de otro modo otorgarnos acceso a un Servicio de Redes Sociales de Terceros, podemos recopilar datos personales que ya estén asociados con la cuenta de su Servicio de Redes Sociales de Terceros, como su nombre, su dirección de correo electrónico, sus actividades o su lista de contactos asociada con esa cuenta.")}</p>

      <h2>{t("Uso de sus datos personales")}</h2>
      <p>{t("La Empresa puede utilizar Datos Personales para los siguientes propósitos:")}</p>
      <ul>
        <li>{t("Para proporcionar y mantener nuestro Servicio, incluso para monitorear el uso de nuestro Servicio.")}</li>
        <li>{t("Para gestionar su Cuenta: para gestionar su registro como usuario del Servicio. Los Datos Personales que proporciona pueden darle acceso a diferentes funcionalidades del Servicio que están disponibles para usted como usuario registrado.")}</li>
        <li>{t("Para la ejecución de un contrato: el desarrollo, cumplimiento y ejecución del contrato de compra de los productos, artículos o servicios que haya adquirido o de cualquier otro contrato con nosotros a través del Servicio.")}</li>
        <li>{t("Para contactarlo: Para contactarlo por correo electrónico, llamadas telefónicas, SMS o otras formas equivalentes de comunicación electrónica, como notificaciones push de una aplicación móvil con respecto a actualizaciones o comunicaciones informativas relacionadas con las funcionalidades, productos o servicios contratados, incluidos los parches de seguridad, cuando sea necesario o razonable para su implementación.")}</li>
        <li>{t("Para proporcionarle noticias, ofertas especiales e información general sobre otros bienes, servicios y eventos que ofrecemos y que son similares a los que ya ha comprado o sobre los que ha preguntado, a menos que haya optado por no recibir dicha información.")}</li>
        <li>{t("Para gestionar sus solicitudes: Para atender y gestionar sus solicitudes hacia nosotros.")}</li>
        <li>{t("Para transferencias comerciales: Podemos utilizar su información para evaluar o llevar a cabo una fusión, desinversión, reestructuración, reorganización, disolución o cualquier otra venta o transferencia de algunos o todos nuestros activos, ya sea como negocio en marcha o como parte de una quiebra, liquidación o procedimiento similar, en el cual los Datos Personales que poseemos sobre los usuarios de nuestro Servicio se encuentran entre los activos transferidos.")}</li>
        <li>{t("Para otros fines: Podemos utilizar su información para otros fines, como el análisis de datos, identificar tendencias de uso, determinar la efectividad de nuestras campañas promocionales y para evaluar y mejorar nuestro Servicio, productos, servicios, marketing y su experiencia.")}</li>
      </ul>

      <h2>{t("Retención de sus datos personales")}</h2>
      <p>{t("La Empresa retendrá sus Datos Personales solo durante el tiempo que sea necesario para los propósitos establecidos en esta Política de Privacidad. Retendremos y utilizaremos sus Datos Personales en la medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si se nos requiere retener sus datos para cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestros acuerdos y políticas legales.")}</p>
      <p>{t("La Empresa también retendrá los Datos de Uso para fines de análisis interno. Los Datos de Uso generalmente se retienen por un período de tiempo más corto, excepto cuando estos datos se utilizan para fortalecer la seguridad o para mejorar la funcionalidad de nuestro Servicio, o cuando estamos legalmente obligados a retener estos datos por períodos más largos.")}</p>

      <h2>{t("Transferencia de sus datos personales")}</h2>
      <p>{t("Su información, incluidos los Datos Personales, se procesa en las oficinas operativas de la Empresa y en cualquier otro lugar donde se encuentren las partes involucradas en el procesamiento. Esto significa que esta información puede ser transferida a, y mantenida en, computadoras ubicadas fuera de su estado, provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de su jurisdicción.")}</p>
      <p>{t("Su consentimiento a esta Política de Privacidad seguido del envío de dicha información representa su acuerdo con esa transferencia.")}</p>
      <p>{t("La Empresa tomará todas las medidas razonablemente necesarias para garantizar que sus datos sean tratados de manera segura y de acuerdo con esta Política de Privacidad, y no se realizará ninguna transferencia de sus Datos Personales a una organización o un país a menos que existan controles adecuados en su lugar, incluyendo la seguridad de sus datos y otra información personal.")}</p>

      <h2>{t("Eliminación de sus datos personales")}</h2>
      <p>{t("Usted tiene el derecho de eliminar o solicitar que le ayudemos a eliminar los Datos Personales que hemos recopilado sobre usted.")}</p>
      <p>{t("Nuestro Servicio puede darle la capacidad de eliminar cierta información sobre usted desde dentro del Servicio.")}</p>
      <p>{t("Puede actualizar, enmendar o eliminar su información en cualquier momento iniciando sesión en su Cuenta, si tiene una, y visitando la sección de configuración de la cuenta que le permite gestionar su información personal. También puede contactarnos para solicitar acceso, corrección o eliminación de cualquier información personal que nos haya proporcionado.")}</p>
      <p>{t("Sin embargo, tenga en cuenta que podemos necesitar retener cierta información cuando tengamos una obligación legal o una base legal para hacerlo.")}</p>

      <h2>{t("Divulgación de sus datos personales")}</h2>

      <h3>{t("Transacciones comerciales")}</h3>
      <p>{t("Si la Empresa está involucrada en una fusión, adquisición o venta de activos, sus Datos Personales pueden ser transferidos. Proporcionaremos aviso antes de que sus Datos Personales sean transferidos y se conviertan en objeto de una Política de Privacidad diferente.")}</p>

      <h3>{t("Cumplimiento de la ley")}</h3>
      <p>{t("En determinadas circunstancias, la Empresa puede estar obligada a divulgar sus Datos Personales si así lo requiere la ley o en respuesta a solicitudes válidas de las autoridades públicas (por ejemplo, un tribunal o una agencia gubernamental).")}</p>

      <h3>{t("Otras obligaciones legales")}</h3>
      <p>{t("La Empresa puede divulgar sus Datos Personales en la creencia de buena fe de que dicha acción es necesaria para:")}</p>
      <ul>
        <li>{t("Cumplir con una obligación legal")}</li>
        <li>{t("Proteger y defender los derechos o la propiedad de la Empresa")}</li>
        <li>{t("Prevenir o investigar posibles irregularidades en relación con el Servicio")}</li>
        <li>{t("Proteger la seguridad personal de los Usuarios del Servicio o del público")}</li>
        <li>{t("Protegerse contra la responsabilidad legal")}</li>
      </ul>

      <h2>{t("Seguridad de sus datos personales")}</h2>
      <p>{t("La seguridad de sus Datos Personales es importante para nosotros, pero recuerde que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100% seguro. Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger sus Datos Personales, no podemos garantizar su seguridad absoluta.")}</p>

      <h2>{t("Privacidad de los niños")}</h2>
      <p>{t("Nuestro Servicio no se dirige a personas menores de 13 años. No recopilamos a sabiendas información de identificación personal de personas menores de 13 años. Si usted es un padre o tutor y sabe que su hijo nos ha proporcionado Datos Personales, por favor contáctenos. Si nos damos cuenta de que hemos recopilado Datos Personales de cualquier persona menor de 13 años sin verificación del consentimiento de los padres, tomamos medidas para eliminar esa información de nuestros servidores.")}</p>
      <p>{t("Si necesitamos basarnos en el consentimiento como base legal para procesar su información y su país requiere el consentimiento de un padre, podemos requerir el consentimiento de su padre antes de recopilar y utilizar esa información.")}</p>

      <h2>{t("Enlaces a otros sitios web")}</h2>
      <p>{t("Nuestro Servicio puede contener enlaces a otros sitios web que no son operados por nosotros. Si hace clic en un enlace de terceros, será dirigido al sitio de ese tercero. Le recomendamos encarecidamente que revise la Política de Privacidad de cada sitio que visite.")}</p>
      <p>{t("No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de los sitios o servicios de terceros.")}</p>

      <h2>{t("Cambios a esta Política de Privacidad")}</h2>
      <p>{t("Podemos actualizar nuestra Política de Privacidad de vez en cuando. Le notificaremos cualquier cambio publicando la nueva Política de Privacidad en esta página.")}</p>
      <p>{t("Le informaremos por correo electrónico y/o un aviso destacado en nuestro Servicio, antes de que el cambio entre en vigor y actualizaremos la fecha de 'Última actualización' en la parte superior de esta Política de Privacidad.")}</p>
      <p>{t("Se le recomienda que revise esta Política de Privacidad periódicamente para verificar si hay cambios. Los cambios a esta Política de Privacidad son efectivos cuando se publican en esta página.")}</p>

      <h2>{t("Contáctenos")}</h2>
      <p>{t("Si tiene alguna pregunta sobre esta Política de Privacidad, puede contactarnos:")}</p>
      <ul>
        <li>{t("Por correo electrónico: comite@cecspace.org")}</li>
        <li>{t("Visitando esta página en nuestro sitio web: cecspace.org")}</li>
      </ul>
    </div>
  );
};

export default PrivacyPolicyContent;