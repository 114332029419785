import { useState } from "react";
import { Col, Row, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { HandbookContainer } from "./Handbook.stylesheets";

export const HandbookContent = () => {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState("es");

  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang);
  };

  const pdfUrl =
    selectedLanguage === "es"
      ? "assets/files/handbook_cec_2024_spanish.pdf"
      : "assets/files/handbook_cec_2024_english.pdf";

  return (
    <HandbookContainer>
      <Row>
        <Col lg="12">
          <h3 className="justify-content-center text-center title pb-5">
            {t("Manual del Participante 2024")}
          </h3>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg="3" className="text-center">
          <Button
            color={selectedLanguage === "es" ? "primary" : "secondary"}
            onClick={() => handleLanguageChange("es")}
            style={{ width: '100%' }}
          >
            {t("Ver en Español")}
          </Button>
        </Col>
        <Col lg="3" className="text-center">
          <Button
            color={selectedLanguage === "en" ? "primary" : "secondary"}
            onClick={() => handleLanguageChange("en")}
            style={{ width: '100%' }}
          >
            {t("Ver en Inglés")}
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg="12">
          <iframe
            src={pdfUrl}
            width="100%"
            height="600px"
            title="Handbook CEC 2024"
            style={{ border: "none" }}
          >
            {t("Este navegador no soporta PDFs. Descarga el PDF para verlo:")}{" "}
            <a href={pdfUrl}>{t("Descargar PDF")}</a>.
          </iframe>
        </Col>
      </Row>
    </HandbookContainer>
  );
};



