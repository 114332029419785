import React, { Suspense } from "react";
import routes from "./routes";
import { Route, Routes } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { MainConfigProvider } from "./contexts/StoreContext";
import { Spinner } from "reactstrap";

const App = () => {
  return (
    <MainConfigProvider>
      <Suspense fallback={<Spinner />}>
        <I18nextProvider i18n={i18n}>
          <Routes>
            {routes.map((route, idx) => (
              <Route path={route.path} element={route.component} key={idx} />
            ))}
            <Route
              path="*"
              element={<p className="text-center">{"Coming Soon"}</p>}
              key="not-found"
            />
          </Routes>
        </I18nextProvider>
      </Suspense>
    </MainConfigProvider>
  );
};

export default App;
