import Section from "./Section";
import { Contact } from "../../component/Contact";
import Footer from "../../component/Footer/Footer";
import { Navbar } from "../../component/Navbar/NavBar";
import { Sponsors } from "../../component/Sponsors";

export const LandingPage = () => {
  return (
    <>
      <Navbar />
      <Section />
      <Sponsors />
      <Contact />
      <Footer />
    </>
  );
};
