import Footer from "../../component/Footer/Footer";
import { Navbar } from "../../component/Navbar/NavBar";
import { PreviousEditionsContent } from "./PreviousEditions.content";

export const PreviousEditionsPage = () => {
  return (
    <div className="d-flex flex-column vh-100">
      <Navbar fixedTop={false} />
      <PreviousEditionsContent />
      <Footer />
    </div>
  );
};
