import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScholarshipsCard } from "../../component/ScholarshipsCard";

const ScholarshipsData = {
  "LISTADO DE BECAS": [
    {
      image: "/assets/images/scholarships/planet.jpg",
      title: "Beca Representante Planet",
      sponsor: "Planet Labs",
      status: "Cerrada",
      deadline: "Viernes 2 de agosto de 2024",
      covers: "Inscripción y registro al congreso",
      numberOfScholarships: "2",
      link: "https://forms.gle/qNeKKd3EJAYfDvuk7"
    },
    {
      image: "/assets/images/scholarships/jaguar.jpg",
      title: "Beca Futuro Estelar Jaguar",
      sponsor: "Jaguar Space, LLC",
      status: "Cerrada",
      deadline: "Viernes 2 de agosto de 2024",
      covers: "Inscripción y registro al congreso",
      numberOfScholarships: "2",
      link: "https://forms.gle/X6CnMhqmrNe5K7M17"
    },
    {
      image: "/assets/images/scholarships/epistemas.jpg",
      title: "Beca SciComm Junior de EpiSTEMas",
      sponsor: "EpiSTEMas Podcast",
      status: "Abierta",
      deadline: "Viernes 16 de agosto de 2024",
      covers: "Inscripción y registro al congreso",
      numberOfScholarships: "2",
      link: "https://forms.gle/X6CnMhqmrNe5K7M18"
    },
  ],
};

export const ScholarshipsContent = () => {
  const { t } = useTranslation();

  return (
    <div style={{ padding: "20px" }}>
      {Object.keys(ScholarshipsData).map((zone) => (
        <div key={zone} style={{ marginBottom: "40px" }}>
          <h2 style={{ textAlign: "center", marginBottom: "20px" }}>{t(zone)}</h2>
          <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
            {ScholarshipsData[zone].map((scholarship, index) => (
              <ScholarshipsCard key={index} {...scholarship} title={t(scholarship.title)} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
