import Footer from "../../component/Footer/Footer";
import { Navbar } from "../../component/Navbar/NavBar";
import { HandbookContent } from "./Handbook.content";

export const HandbookPage = () => {
  return (
    <div className="d-flex flex-column vh-100">
      <Navbar fixedTop={false} />
      <HandbookContent />
      <Footer />
    </div>
  );
};
