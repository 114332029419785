import { useTranslation } from "react-i18next";
import { Col, Container, Form, Row, Button } from "reactstrap";

export const Contact = () => {
  const { t } = useTranslation();

  return (
    <section className="section bg-dark" id="contact">
      <Container>
        <Row>
          <Col>
            <h4 className=" text-white text-center">
              {t(
                "Suscríbete a nuestro boletín para recibir las últimas noticias del congreso, directo a tu correo:"
              )}
            </h4>
          </Col>
        </Row>
        <div className="text-center search-form mt-4">
          <Form
            method="post"
            action="https://cecspace.us21.list-manage.com/subscribe/post?u=c75d808c27544f8648a08c4b1&amp;id=13744f88a0&amp;f_id=0034f8e6f0"
            name="contact-form"
          >
            <input
              type="email"
              name="EMAIL"
              placeholder={t("Correo electrónico")}
              required
            />
            <Button className="btn btn-primary">{t("Suscribirse")}</Button>
          </Form>
        </div>
      </Container>
    </section>
  );
};
