import { Contact } from "../../component/Contact";
import Footer from "../../component/Footer/Footer";
import { Navbar } from "../../component/Navbar/NavBar";
import { ProgramContent } from "./Program.content";

export const ProgramPage = () => {
  return (
    <div className="d-flex flex-column vh-100">
      <Navbar fixedTop={false} />
      <ProgramContent />
      <Contact />
      <Footer />
    </div>
  );
};

export default ProgramPage;
