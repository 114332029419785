import { Contact } from "../../component/Contact";
import Footer from "../../component/Footer/Footer";
import { Navbar } from "../../component/Navbar/NavBar";
import { RegistrationContent } from "./Registration.content";

export const RegistrationPage = () => {
  return (
    <div className="d-flex flex-column vh-100">
      <Navbar fixedTop={false} />
      <RegistrationContent />
      <Contact />
      <Footer />
    </div>
  );
};
