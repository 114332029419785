import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";

export const Team = () => {
  const { t } = useTranslation();
  const teamData = [
    {
      profile: "assets/images/team/teammate6.jpg",
      name: "MSc. M.A. José Bagur",
      description: "Universidad del Valle de Guatemala",
      position: "Coordinador del Laboratorio de Aeroespacial",
      linkedinUrl: "https://www.linkedin.com/in/joseantoniobagur/",
    },
    {
      profile: "assets/images/team/teammate2.png",
      name: "Dra. Leonora de Lemos",
      description: "Universidad de Costa Rica",
      position: "Coordinadora Grupo de Ingeniería Aeroespacial",
      linkedinUrl: "https://www.linkedin.com/in/leonora-de-lemos-116b56ab/",
    },
    {
      profile: "assets/images/team/teammate5.png",
      name: "Licda. Katherinne Herrera-Jordan",
      description: "Verne Technologies",
      position: "Fundadora y Directora",
      linkedinUrl:
        "https://www.linkedin.com/in/katherinne-herrera-jordan-780904154/",
    },
    {
      profile: "assets/images/team/teammate4.png",
      name: "Dr. Javier Mejuto",
      description: "Universidad Nacional Autónoma de Honduras",
      position: "Decano de la Facultad de Ciencias Espaciales",
      linkedinUrl: "https://www.linkedin.com/in/javier-mejuto-44262426/",
    },
    {
      profile: "assets/images/team/teammate1.png",
      name: "Dr. Andrés Mora",
      description: "NASA Ames Research Center",
      position: "Astrobee Engineering Lead",
      linkedinUrl: "https://www.linkedin.com/in/andresmora/",
    },
    {
      profile: "assets/images/team/teammate3.png",
      name: "Dr. Luis Zea",
      position: "Profesor Adjunto",
      description: "Universidad de Colorado Boulder",
      position2: "Fundador",
      description2: "Jaguar Space, LLC",
      linkedinUrl: "https://www.linkedin.com/in/spaceluiszea/",
    },
  ];

  return (
    <>
      <section className="section bg-light" id="team">
        <Container>
          <Row className="justify-content-center text-center">
            <Col>
              <h3 className="title mb-5">
                {t("ACERCA DEL CONGRESO ESPACIAL CENTROAMERICANO 2024")}
              </h3>
              <p className="text-muted mt-3 title-subtitle mx-auto text-justified">
                {t("aboutInfo1")}
              </p>
              <p className="text-muted mt-3 title-subtitle mx-auto text-justified">
                {t("aboutInfo2")}
              </p>
              <p className="text-muted mt-3 title-subtitle mx-auto text-justified">
                {t("aboutInfo3")}
              </p>
              <p className="text-muted mt-3 title-subtitle mx-auto text-justified">
                {t("aboutInfo4")}
              </p>
              <p className="text-muted mt-3 title-subtitle mx-auto text-justified"
                 dangerouslySetInnerHTML={{
                   __html: t("aboutInfo5").replace(
                     "Nota sobre el idioma:",
                     "<strong>Nota sobre el idioma:</strong>"
                   )
                 }}
              ></p>
            </Col>
          </Row>
          <Row className="justify-content-center text-center mt-5">
            <Col lg={12}>
              <h3 className="title">{t("COMITÉ ORGANIZADOR")}</h3>
            </Col>
          </Row>
          <Row className="mt-5">
            {teamData.map((teammate, key) => (
              <Col lg={4} key={key}>
                <div className="text-center bg-white team-box mt-3 p-5 active">
                  <div className="">
                    <img
                      src={teammate.profile}
                      alt={t(teammate.name)}
                      className="img-fluid rounded-circle mx-auto d-block"
                    />
                  </div>
                  <div className="team-name" style={{ height: 200 }}>
                    <p className="fw-bold mb-0 mt-4 fs-5">{t(teammate.name)}</p>
                    <p className="mt-4">{t(teammate.position)}</p>
                    <p className="text-muted">{t(teammate.description)}</p>
                    {teammate.position2 && teammate.description2 && (
                      <>
                        <p className="mt-4">{t(teammate.position2)}</p>
                        <p className="text-muted">{t(teammate.description2)}</p>
                      </>
                    )}
                  </div>
                  <div className="">
                    <ul className="list-inline team-social mt-4 mb-0">
                      <li className="list-inline-item">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={teammate.linkedinUrl}
                        >
                          <i className="ti-linkedin" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};
