import { useTranslation } from "react-i18next";
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";
import styled from "styled-components";

export const StyledCard = styled(Card)`
  width: 300px;
  margin: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribuye espacio entre elementos */

  .card-img-top {
    height: 200px;
    object-fit: cover;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distribuye espacio entre elementos */
    flex-grow: 1;
  }

  .hotel-info {
    margin-bottom: 10px;
  }

  .hotel-link {
    color: blue;
    text-decoration: underline;
  }

  .price {
    font-size: 1.2em;
    color: green;
  }

  .original-price {
    text-decoration: line-through;
    color: #999;
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: auto;
  }
`;

export const HotelCard = ({
  image,
  title,
  address,
  link,
  approximateCost,
  estimatedTime,
  note,
  includesBreakfast // A new prop to indicate if breakfast is included or not
}) => {
  const { t } = useTranslation();
  return (
    <StyledCard>
      <CardImg top width="100%" src={image} alt={title} />
      <CardBody>
        <div>
          <CardTitle tag="h5">{title}</CardTitle>
          <CardText className="hotel-info"><strong>{t("Dirección")}:</strong> {address}</CardText>
          <CardText className="hotel-info">
            <strong>{t("Costo aproximado por noche")}:</strong> {includesBreakfast ? t("Desde {{price}} por noche (incluye desayuno)", { price: approximateCost }) : t("Desde {{price}} por noche (no incluye desayuno)", { price: approximateCost })}
          </CardText>
          <CardText className="hotel-info"><strong>{t("Tiempo estimado para llegada a la UVG")}:</strong> {t(estimatedTime)}</CardText>
          <CardText className="hotel-info"><em>{t(note)}</em></CardText>
        </div>
        <div className="button-container">
          <Button color="primary" onClick={() => window.open(link, "_blank")}>{t("Reservar")}</Button>
        </div>
      </CardBody>
    </StyledCard>
  );
};