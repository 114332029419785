import Footer from "../../component/Footer/Footer";
import { Navbar } from "../../component/Navbar/NavBar";
import { Venuecontent } from "./Venue.content";

export const VenuePage = () => {
  return (
    <div className="d-flex flex-column vh-100">
      <Navbar fixedTop={false} />
      <Venuecontent />
      <Footer />
    </div>
  );
};
