import { useTranslation } from "react-i18next";
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";
import styled from "styled-components";

export const StyledCard = styled(Card)`
  width: 300px;
  margin: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .card-img-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

  .info {
    margin-bottom: 10px;
  }

  .link {
    color: blue;
    text-decoration: underline;
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: auto;
  }
`;

export const ScholarshipsCard = ({
  image,
  title,
  sponsor,
  status,
  deadline,
  covers,
  numberOfScholarships,
  link
}) => {
  const { t } = useTranslation();

  return (
    <StyledCard>
      <div className="card-img-container">
        <CardImg className="card-img" src={image} alt={title} />
      </div>
      <CardBody>
        <div>
          <CardTitle tag="h5">{t(title)}</CardTitle>
          <CardText className="info"><strong>{t("Sponsor")}:</strong> {t(sponsor)}</CardText>
          <CardText className="info"><strong>{t("Status")}:</strong> {t(status)}</CardText>
          <CardText className="info"><strong>{t("Deadline")}:</strong> {t(deadline)}</CardText>
          <CardText className="info"><strong>{t("Covers")}:</strong> {t(covers)}</CardText>
          <CardText className="info"><strong>{t("Number of Scholarships")}:</strong> {numberOfScholarships}</CardText>
        </div>
        {status === "Abierta" && (
          <div className="button-container">
            <Button color="primary" onClick={() => window.open(link, "_blank")}>{t("¡Postúlate!")}</Button>
          </div>
        )}
      </CardBody>
    </StyledCard>
  );
};
