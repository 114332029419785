import { Col } from "reactstrap";
import {
  JustifiedText,
  PreviousEditionsContainer,
  PreviousEditionsRow,
} from "./PreviousEditions.stylesheets";
import { useTranslation } from "react-i18next";

export const PreviousEditionsContent = () => {
  const { t } = useTranslation();
  return (
    <PreviousEditionsContainer className="vertical-content">
      <PreviousEditionsRow className="align-items-center">
        <Col className="p-2 d-block d-lg-none">
          <img
            src="/assets/images/cec2023.png"
            className="img-thumbnail"
            alt="CEC SPACE 2023"
          />
        </Col>
        <Col lg="6">
          <p className="fs-3">{t("EDICIONES ANTERIORES")} </p>
          <JustifiedText>{t("previousEditionsInfo")}</JustifiedText>
          <JustifiedText>
            {t("Más información del CEC 2023 en el siguiente enlace:")}
          </JustifiedText>
          <a
            href="https://cec-2023.sitrus.ai/"
            className="btn btn-primary me-1 my-3"
            target="_blank"
            rel="noreferrer"
          >
            {t("CEC 2023")}
          </a>
        </Col>
        <Col className="p-2 d-none d-lg-block">
          <img
            src="/assets/images/cec2023.png"
            className="img-thumbnail"
            alt="CEC SPACE 2023"
          />
          <p className="fw-light text-end">
            {t("Participantes del CEC 2023 afuera del Aula Magna de la UCR")}
            <br />
            {t("Créditos de la fotografía: Andrés Mora")}
          </p>
        </Col>
      </PreviousEditionsRow>
    </PreviousEditionsContainer>
  );
};
