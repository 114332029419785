import React from 'react';
import { Container, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { SLUGS } from "../../contstants/Contstants";
import './styles.css';
import CountdownTimer from './CountdownTimer'; // Asegúrate de que la ruta al archivo es correcta

const Section = () => {
  const { t } = useTranslation();
  const eventDate = new Date('September 10, 2024 08:30:00');

  return (
    <>
      <section className="bg-home vh-100" id="home">
        <div className="home-center">
          <div className="home-desc-center ">
            <Container>
              <Row className="justify-content-center">
                <Col lg="1"> </Col>
                <Col>
                  <div className="text-white text-center bg-dark-transparent p-4">
                    <h1 className="home-title mb-0">
                      {t("II CONGRESO ESPACIAL CENTROAMERICANO")}
                    </h1>
                    <h3 className="mt-4 mb-0 mx-auto">
                      {t("10 al 12 de septiembre de 2024")}
                    </h3>
                    <h3 className="mb-0 mx-auto">
                      {t("Universidad del Valle de Guatemala (UVG)")}
                    </h3>
                    <h3 className="mb-0 mx-auto">
                      {t("Ciudad de Guatemala, Guatemala")}
                    </h3>
                    <h3 className="mt-4 mb-0 mx-auto">
                      {t("¡Promoviendo la colaboración en proyectos espaciales, uniendo a Centroamérica con el Espacio y el mundo!")}
                    </h3>
                    {/* Countdown Timer */}
                    <CountdownTimer targetDate={eventDate} />
                  </div>
                </Col>
                <Col lg="1"> </Col>
              </Row>
              <Row>
                <Col className="text-center mt-5 ">
                  <a href={SLUGS.ABOUT} className="btn btn-dark btn-round m-2">
                    {t("ACERCA DEL CONGRESO")}
                  </a>
                  <a href={SLUGS.CALLFORABSTRACTS} className="btn btn-dark btn-round m-2">
                    {t("LLAMADO A PONENCIAS")}
                  </a>
                  <a href="#contact" className="btn btn-dark btn-round m-2">
                    {t("NEWSLETTER")}
                  </a>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section;