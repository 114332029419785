import { createContext, useContext, useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { localStorageKey } from "../contstants/Contstants";

export const MainConfig = createContext(null);

export const useMainConfig = () => {
  const value = useContext(MainConfig);
  if (value === null) {
    throw new Error("MainConfig was used outside of the provider");
  }
  return value;
};

export const MainConfigProvider = ({ children }) => {
  const localStorageConfig = JSON.parse(
    localStorage.getItem(localStorageKey) ?? "{}"
  );

  const [config, setConfig] = useState(
    localStorageConfig ?? {
      language: "en",
    }
  );

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(config));
  }, [config]);

  const changeLanguage = (language) => {
    setConfig((prev) => {
      return {
        ...prev,
        language: language,
      };
    });
  };

  if (config) {
    return (
      <MainConfig.Provider
        value={{
          ...config,
          actions: {
            changeLanguage,
          },
        }}
      >
        {children}
      </MainConfig.Provider>
    );
  }

  return <Spinner />;
};
