import { Contact } from "../../component/Contact";
import Footer from "../../component/Footer/Footer";
import { Navbar } from "../../component/Navbar/NavBar";
import { ScholarshipsContent } from "./Scholarships.content";
import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";
import { ScholarshipsPageContainer } from "./Scholarships.stylesheets";

export const ScholarshipsPage = () => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column vh-100">
      <Navbar fixedTop={false} />
      <ScholarshipsPageContainer>
        <Row>
          <Col>
            <h3 className="justify-content-center text-center title">
              {t("BECAS PARA EL CONGRESO ESPACIAL CENTROAMERICANO 2024")}
            </h3>
          </Col>
        </Row>

        <Row>
          <Col>
            <p className="mt-5" align="justify">
              {t("El Congreso Espacial Centroamericano (CEC) 2024 tiene como objetivo servir como una plataforma fundamental para facilitar oportunidades de colaboración entre instituciones y personas tanto en la región centroamericana como a nivel mundial. Uno de los principales objetivos del CEC es que sea accesible para la mayor cantidad de personas posible, por lo que este año nuevamente hemos unido esfuerzos con empresas e instituciones comprometidas con el desarrollo del sector espacial en la región centroamericana, para ofrecer becas de inscripción a todos los interesados. Extendemos una cordial invitación a los estudiantes que deseen participar en las becas disponibles, las cuales, se describen a continuación.")}
            </p>
          </Col>
        </Row>

        <ScholarshipsContent />
      </ScholarshipsPageContainer>
      <Contact />
      <Footer />
    </div>
  );
};
