import Footer from "../../component/Footer/Footer";
import { Navbar } from "../../component/Navbar/NavBar";
import PrivacyPolicyContent from './PrivacyPolicy.content';


export const PrivacyPolicyPage = () => {
  return (
    <div className="d-flex flex-column vh-100">
      <Navbar fixedTop={false} />
      <PrivacyPolicyContent />
      <Footer />
    </div>
  );
};