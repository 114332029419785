import { Container, Row } from "reactstrap";
import styled from "styled-components";

export const VenueContainer = styled(Container)`
  margin-top: 6%;
  margin-bottom: 6%;
`;

export const VenueRow = styled(Row)``;

export const JustifiedText = styled.p`
  text-align: justify;
`;
