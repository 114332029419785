import { Container, Row } from "reactstrap";
import styled from "styled-components";

export const VenueCityContainer = styled(Container)`
  margin-top: 6%;
  margin-bottom: 6%;
`;

export const PVenueCityRow = styled(Row)``;

export const JustifiedText = styled.p`
  text-align: justify;
`;
