import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";

export const Team = () => {
  const { t } = useTranslation();
  const teamData = [
    {
      profile: "assets/images/speakers/Sandra_Cauffman.jpg",
      name: "Sandra Cauffman",
      description: "NASA",
      position: "Deputy Director, Astrophysics Division",
      linkedinUrl: "https://www.linkedin.com/in/sandra-cauffman-6681665/",
    },
    {
      profile: "assets/images/speakers/Lucas_Paganini.jpg",
      name: "Lucas Paganini",
      description: "NASA",
      position: "Deputy Program Executive for the Roman Space Telescope",
      linkedinUrl: "https://www.linkedin.com/in/lucaspaganini/",
    },
    {
      profile: "assets/images/speakers/Yaitza_Luna.jpg",
      name: "Yaítza Luna-Cruz",
      position: "Program Executive, Earth Science Data Systems",
      description: "NASA",
      linkedinUrl: "https://www.linkedin.com/in/ya%C3%ADtza-luna-cruz-ph-d-47b2a596/",
    },
    {
      profile: "assets/images/speakers/Maximo_Roa.jpg",
      name: "Máximo A. Roa",
      position: "Senior Research Scientist",
      description: "German Aerospace Center (DLR)",
      linkedinUrl: "https://www.linkedin.com/in/maximoroa/",
    },
    {
      profile: "assets/images/speakers/Mariela_Sammaritano.jpg",
      name: "Mariela Lucia Aguilera Sammaritano",
      position: "Directora del Instituto de Altos Estudios Espaciales Mario Gulich",
      description: "Comisión Nacional de Actividades Espaciales (CONAE)",
      linkedinUrl: "https://www.linkedin.com/in/mariela-luc%C3%ADa-aguilera-sammaritano-7b090837/",
    },
    {
      profile: "assets/images/speakers/Haitham_Akah.jpg",
      name: "Haitham Akah",
      position: "Head of Space Communication Sector",
      description: "Egyptian Space Agency",
      linkedinUrl: "https://www.linkedin.com/in/haitham-akah-a9374966/",
    },
    {
      profile: "assets/images/speakers/Hilary_Cohen.jpg",
      name: "Hilary Cohen",
      description: "HEO",
      position: "Director of Operations",
      linkedinUrl: "https://www.linkedin.com/in/hilaryrcohen/",
    },
    {
      profile: "assets/images/speakers/Kyoichi_Arakane.jpg",
      name: "Kyoichi Arakane",
      position: "Associate Senior Engineer",
      description: "Japan Aerospace Exploration Agency (JAXA)",
      linkedinUrl: "https://www.linkedin.com/in/kyoichi-arakane-36a9a58/",
    },
    {
      profile: "assets/images/speakers/Jonathan_Barlow.jpg",
      name: "Jonathan S. Barlow",
      description: "KBR/NASA Ames Research Center",
      position: "SPHERES/Astrobee Facility Project Manager",
      linkedinUrl: "https://www.linkedin.com/in/jonathan-barlow-1865229/",
    },
    {
      profile: "assets/images/speakers/Gabriela_Montenegro.jpg",
      name: "Gabriela Montenegro Bethancourt",
      description: "Gobierno de Guatemala",
      position: "Secretaria Nacional de Ciencia y Tecnología",
      linkedinUrl: "https://www.linkedin.com/in/gabriela-montenegro-bethancourt-7936a563/",
    },
    {
      profile: "assets/images/speakers/Victor_Ayerdi.png",
      name: "Victor Hugo Ayerdi Bardales",
      description: "Universidad del Valle de Guatemala",
      position: "Decano de la Facultad de Ingeniería",
      linkedinUrl: "https://www.linkedin.com/in/victor-ayerdi-2b19b8145/",
    },
    {
      profile: "assets/images/speakers/Ines_dAvila.jpg",
      name: "Inês d'Ávila",
      description: "Portuguese Space Agency",
      position: "Space Safety and Transportation Officer",
      linkedinUrl: "https://www.linkedin.com/in/inessalesavila/",
    },
    {
      profile: "assets/images/speakers/Carlos_Duarte.jpg",
      name: "Carlos Duarte",
      description: "Agencia Espacial Mexicana",
      position: "Coordinador General de Formación de Capital Humano en el Campo Espacial",
      linkedinUrl: "https://www.linkedin.com/in/karlozduarte/",
    },
    {
      profile: "assets/images/speakers/Fernando_Zorto.png",
      name: "Fernando Zorto",
      description: "Universidad Nacional Autónoma de Honduras",
      position: "Director del Instituto de Investigaciones en Ciencias Aplicadas y Tecnológicas",
      linkedinUrl: "https://www.linkedin.com/in/fernando-zorto-8b536429/",
    },
    {
      profile: "assets/images/speakers/Maria_del_Barco.jpg",
      name: "María Fernanda del Barco León",
      description: "Orbital Space Technologies",
      position: "Chief Administrative Officer",
      linkedinUrl: "https://www.linkedin.com/in/ferdelbarco/",
    },
    {
      profile: "assets/images/speakers/Elzbieta_Jablonska.jpg",
      name: "Elżbieta Jabłońska",
      description: "CASSINI Business Accelerator",
      position: "Business Mentor for Space Industry Start-Ups",
      linkedinUrl: "https://www.linkedin.com/in/matias-campos-asl/",
    },
    {
      profile: "assets/images/speakers/Matias_Campos.jpg",
      name: "Matías Campos Abad",
      description: "Astralintu Space Technologies",
      position: "CEO y Fundador",
      linkedinUrl: "https://www.linkedin.com/in/el%C5%BCbieta-jab%C5%82o%C5%84ska-b209381b/",
    },
    {
      profile: "assets/images/speakers/Alina_Nassar.png",
      name: "Alina Nassar Jorge",
      position: "Space Consultant",
      description: "Nassar Abogados",
      linkedinUrl: "https://www.linkedin.com/in/alina-nassar-73730613/",
    },
    {
      profile: "assets/images/speakers/Gaspar_Yataz.png",
      name: "Gaspar Yataz Pop",
      position: "Analista de Datos e Investigador Científico",
      description: "Fundación Kajulew",
      linkedinUrl: "https://www.linkedin.com/in/gaspar-yataz-pop-78a211230/",
    },
    {
      profile: "assets/images/speakers/Emilio_Eva.jpg",
      name: "Emilio Alejandro Eva Arévalo",
      position: "Fundador",
      description: "Observatorio Astronómico Guatemala",
      linkedinUrl: "https://www.linkedin.com/in/emilio-alejandro-eva-ar%C3%A9valo-49620978/",
    },
    {
      profile: "assets/images/speakers/Walt_Everetts.jpeg",
      name: "Walt Everetts",
      position: "Space Consultant",
      linkedinUrl: "https://www.linkedin.com/in/walt-everetts-8881182/",
    },
    {
      profile: "assets/images/speakers/Jose_Fernandez.jpg",
      name: "José Luis Fernández Cabrera",
      position: "Jefe de Operaciones en Inversiones y Capitales Rotativos",
      description: "ÍCARO S.A.",
      linkedinUrl: "https://www.linkedin.com/in/jos%C3%A9-luis-fern%C3%A1ndez-cabrera-29109a18a/",
    },
    {
      profile: "assets/images/speakers/Laura_Somarriba.jpg",
      name: "Laura Somarriba Soley",
      position: "Gestión Tecnológica y Capital de Riesgo",
      description: "ÍCARO S.A.",  
      linkedinUrl: "https://www.linkedin.com/in/laura-somarriba-6667532a/",
    },
  ];

  return (
    <>
      <section className="section bg-light" id="team">
        <Container>
          <Row className="justify-content-center text-center">
            <Col>
              <h3 className="title mb-5">
                {t("CONFERENCISTAS CONFIRMADOS PARA EL CEC 2024")}
              </h3>
            </Col>
          </Row>
          <Row className="mt-5">
            {teamData.map((teammate, key) => (
              <Col lg={4} key={key}>
                <div className="text-center bg-white team-box mt-3 p-5 active">
                  <div className="">
                    <img
                      src={teammate.profile}
                      alt={t(teammate.name)}
                      className="img-fluid rounded-circle mx-auto d-block"
                    />
                  </div>
                  <div className="team-name" style={{ height: 200 }}>
                    <p className="fw-bold mb-0 mt-4 fs-5">{t(teammate.name)}</p>
                    <p className="mt-4">{t(teammate.position)}</p>
                    <p className="text-muted">{t(teammate.description)}</p>
                    {teammate.position2 && teammate.description2 && (
                      <>
                        <p className="mt-4">{t(teammate.position2)}</p>
                        <p className="text-muted">{t(teammate.description2)}</p>
                      </>
                    )}
                  </div>
                  <div className="">
                    <ul className="list-inline team-social mt-4 mb-0">
                      <li className="list-inline-item">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={teammate.linkedinUrl}
                        >
                          <i className="ti-linkedin" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};
