import { Col } from "reactstrap";
import { JustifiedText, VenueContainer, VenueRow } from "./Venue.stylesheets";
import { useTranslation } from "react-i18next";

export const Venuecontent = () => {
  const { t } = useTranslation();
  return (
    <VenueContainer className="vertical-content">
      <VenueRow className="align-items-center">
        <Col className="p-2 d-block d-lg-none">
          <img
            src="/assets/images/uvg.jpeg"
            className="img-thumbnail"
            alt="..."
          />
        </Col>
        <Col lg="6">
          <p className="fs-3">{t("LA SEDE DEL CONGRESO PARA EL 2024")} </p>
          <JustifiedText>{t("venueInfo")}</JustifiedText>
          <JustifiedText>
            {t(
              "Más información de la UVG y sus proyectos relacionados con el espacio en los siguientes enlaces:"
            )}
          </JustifiedText>
          <a
            href="https://www.uvg.edu.gt/"
            className="btn btn-primary me-1 my-3"
            target="_blank"
            rel="noreferrer"
          >
            {t("UVG")}
          </a>
          <a
            href="https://www.uvg.edu.gt/cubesat/"
            className="btn btn-primary me-1 my-3"
            target="_blank"
            rel="noreferrer"
          >
            {t("QUETZAL-1")}
          </a>
          <a
            href="https://www.colorado.edu/faculty/zea-luis/space-biofilms/"
            className="btn btn-primary me-1 my-3"
            target="_blank"
            rel="noreferrer"
          >
            {t("SPACE BIOFILMS")}
          </a>
          <a
            href="https://www.colorado.edu/faculty/zea-luis/deep-space-radiation-genomics-dsrg-artemis-i/"
            className="btn btn-primary my-3"
            target="_blank"
            rel="noreferrer"
          >
            {t("DSRG")}
          </a>
        </Col>
        <Col className="p-2 d-none d-lg-block">
          <img
            src="/assets/images/uvg.jpeg"
            className="img-thumbnail"
            alt="..."
          />
          <p className="fw-light text-end">
            {t("Centro de Innovación y Tecnología (CIT) de la UVG")}
            <br />
            {t("Créditos de la fotografía: UVG")}
          </p>
        </Col>
      </VenueRow>
    </VenueContainer>
  );
};
