import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { CallForAbstractsContentContainer } from "./CallForAbstracts.stylesheets";

export const CallForAbstractsContent = () => {
  const { t } = useTranslation();
  return (
    <CallForAbstractsContentContainer>
      <Row>
        <Col>
          <h3 className="justify-content-center text-center title">
            {t(
              "LLAMADO A PONENCIAS PARA EL CONGRESO ESPACIAL CENTROAMERICANO 2024"
            )}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col className="text-center text-muted title-subtitle mx-auto text-justified">
          <p className="mt-5">
            {t(
              "Ya estamos aceptando resúmenes para el Congreso Espacial Centroamericano (CEC) 2024, que se celebrará en la Universidad del Valle de Guatemala (UVG), en la Ciudad de Guatemala, del 10 al 12 de septiembre de 2024."
            )}
          </p>
          <p>
            {t(
              "El CEC 2024 se reafirma como un foro fundamental para que personas de Centroamérica, tanto de la región como del exterior, compartan sus proyectos actuales con la comunidad regional y global. Este evento tiene como objetivo facilitar la colaboración entre instituciones y personas a nivel regional y global, además de proporcionar una plataforma para que los startups espaciales centroamericanas exploren posibles sinergias."
            )}
          </p>
          <p>
            {t(
              `Se invita a la presentación de resúmenes que aborden una amplia variedad de temas espaciales, incluidos, pero no limitados a: `
            )}
            <strong>
              {t(
                "biología y medicina espacial, cohetería, desarrollo de CubeSats (pasado, presente y futuro), desarrollo del ecosistema espacial (políticas públicas), educación y divulgación, inclusión (género y diversidad) en ciencia, tecnología, ingeniería y matemáticas (STEM), innovación y emprendimiento tecnológico espacial, robótica espacial y uso de datos de teledetección. "
              )}
            </strong>
            {t(
              "La convocatoria está abierta a estudiantes, profesionales del ámbito espacial, científicos, legisladores y gestores de proyectos. "
            )}
            <strong>
              {t(
                "El CEC es un evento presencial y no incluirá presentaciones técnicas virtuales."
              )}
            </strong>
          </p>
          <p>
            {t(
              "Cada resumen debe incluir: (i) título, (ii) lista de coautores, (iii) afiliación de cada coautor, (iv) direcciones de correo electrónico de cada coautor, y (v) un resumen de no más de 400 palabras. Los resúmenes seleccionados se convertirán en presentaciones de un máximo de 15 minutos, incluyendo un periodo de preguntas y respuestas."
            )}
          </p>
          <p>
            <strong>
              {t(
                "La fecha límite para enviar los resúmenes es el sábado 15 de junio, "
              )}
            </strong>
            {t("a través del ")}
            <a href="https://easychair.org/my/conference?conf=cec2024">
              {t("siguiente enlace.")}
            </a>
            <strong>
              {t(
                " Se comunicará la selección de resúmenes aproximadamente el domingo 30 de junio de 2024."
              )}
            </strong>
          </p>
          <p>
            {t(
              "Es requisito que al menos un coautor esté registrado en el congreso antes del viernes 12 de agosto para asegurar que la presentación se mantenga en el programa. Tome en cuenta que las trarifas de registro no son reembolsables."
            )}
          </p>
          <p>
            <strong>
              {t(
                "La fecha límite para enviar las presentaciones completas es el sábado 31 de agosto"
              )}
            </strong>
            {t(", a través del siguiente enlace.")}
          </p>
          <p>
            {t(
              "Adicionalmente, este año el CEC ofrecerá la oportunidad de enviar un manuscrito antes del lunes 2 de septiembre, siguiendo el formato que se proporcionará posteriormente. Estos manuscritos serán publicados en las Memorias del CEC 2024. Cabe destacar que esto no es obligatorio."
            )}
          </p>
          <p>
            {t("Para más información sobre el CEC 2024, por favor visite: ")}
            <a href="https://cecspace.org/">cecspace.org</a>
            {t(" Consultas específicas pueden enviarse a: ")}
            <a href="mailto:faq@cecspace.org">faq@cecspace.org</a>
          </p>
        </Col>
      </Row>
    </CallForAbstractsContentContainer>
  );
};
