import { Col, Row, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { ProgramContentContainer } from "./Program.stylesheets";

export const ProgramContent = () => {
  const { t } = useTranslation();

  return (
    <ProgramContentContainer>
      <Row>
        <Col lg="12">
          <h3 className="justify-content-center text-center title pb-5">
            {t("Programa CEC 2024 (Sujeto a Cambios)")}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="text-center">
          <img
            src="assets/images/program_cec_2024.png"
            alt="Programa CEC 2024"
            className="img-fluid"
          />
        </Col>
      </Row>
      <Row className="pt-4">
        <Col lg="12" className="text-center">
          <Button color="primary" href="assets/files/program_cec_2024.pdf" download>
            {t("Descargar el Programa en PDF")}
          </Button>
        </Col>
      </Row>
    </ProgramContentContainer>
  );
};


