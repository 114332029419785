import Footer from "../../component/Footer/Footer";
import { Navbar } from "../../component/Navbar/NavBar";
import { VenueCityContent } from "./VenueCity.content";

export const VenueCityPage = () => {
  return (
    <div className="d-flex flex-column vh-100">
      <Navbar fixedTop={false} />
      <VenueCityContent />
      <Footer />
    </div>
  );
};
