import { Contact } from "../../component/Contact";
import Footer from "../../component/Footer/Footer";
import { Navbar } from "../../component/Navbar/NavBar";
import { CallForAbstractsContent } from "./CallForAbstracts.content";

export const CallForAbstracts = () => {
  return (
    <div className="d-flex flex-column vh-100">
      <Navbar fixedTop={false} />
      <CallForAbstractsContent />
      <Contact />
      <Footer />
    </div>
  );
};
