import { Button } from "reactstrap";
import { useMainConfig } from "../../../contexts/StoreContext";
import { useTranslation } from "react-i18next";

const LanguageButton = () => {
  const {
    language: stateLanguage,
    actions: { changeLanguage },
  } = useMainConfig();

  const { i18n } = useTranslation();

  const updateLanguage = async (language) => {
    await i18n.changeLanguage(language);
    changeLanguage(language);
    window.location.reload(false);
  };

  const otherLanguage = stateLanguage === "en" ? "es" : "en";
  const label = stateLanguage === "en" ? "Español" : "English";

  return (
    <>
      {/* small screens */}
      <Button
        className="d-block d-lg-none"
        color="primary"
        block
        onClick={async () => await updateLanguage(otherLanguage)}
      >
        {label}
      </Button>
      {/* large screens */}
      <Button
        className="d-none d-lg-block"
        color="primary"
        onClick={async () => await updateLanguage(otherLanguage)}
      >
        {label}
      </Button>
    </>
  );
};

export default LanguageButton;
