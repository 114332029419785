import Footer from "../../component/Footer/Footer";
import { Navbar } from "../../component/Navbar/NavBar";
import { AboutContent } from "./About.content";

export const AboutPage = () => {
  return (
    <div className="d-flex flex-column vh-100">
      <Navbar fixedTop={false} />
      <AboutContent />
      <Footer />
    </div>
  );
};
