import es from "./locale/es.json";
import en from "./locale/en.json";

import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { localStorageKey } from "./contstants/Contstants";

const lng =
  JSON.parse(localStorage.getItem(localStorageKey) ?? "{}").language ?? "es";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    lng,
    resources: {
      en,
      es,
    },
    fallbackLng: "es",
    interpolation: {
      escapeValue: false,
    },
  })
  .catch((error) =>
    console.error("failed to initialize translations", {
      error: error instanceof Error ? error.message : "unknown error",
    })
  );

export default i18next;
