import { HotelCard } from "../../component/HotelCard";

const hotelData = {
  "Zona 15": [
    {
      image: "/assets/images/hotels/achotels.jpg",
      title: "AC Hotel Guatemala City",
      address: "Ciudad Cayalá, Edificio B1 11 Avenida 35-02, Zona 16, Ciudad de Guatemala, 01016",
      link: "https://www.marriott.com/es/hotels/guaar-ac-hotel-guatemala-city/overview/",
      approximateCost: "$140",
      estimatedTime: "5 a 10 minutos",
      note: "*El costo de la habitación depende del tipo que se elija.",
      includesBreakfast: false
    },
    {
      image: "/assets/images/hotels/vistaquince.jpg",
      title: "Hotel Vista Quince",
      address: "Ruta 1 4-48, Zona 15, Ciudad de Guatemala, 01015",
      link: "https://www.vistaquince.com/",
      approximateCost: "$140",
      estimatedTime: "5 a 10 minutos",
      note: "*El costo de la habitación depende del tipo que se elija.",
      includesBreakfast: false
    }
  ],
  "Zona 10": [
    {
      image: "/assets/images/hotels/intercontinental.jpg",
      title: "Real Intercontinental Guatemala",
      address: "14 Calle 2-51, Zona 10, Ciudad de Guatemala, 01010",
      link: "https://www.ihg.com/intercontinental/hotels/es/es/guatemala-city/guaha/hoteldetail",
      approximateCost: "$180",
      estimatedTime: "15 a 20 minutos",
      note: "*El costo de la habitación depende del tipo que se elija.",
      includesBreakfast: false
    },
    {
      image: "/assets/images/hotels/westin.jpg",
      title: "The Westin Camino Real",
      address: "14 Calle 0-20, Zona 10, Ciudad de Guatemala, 01010",
      link: "https://www.marriott.com/es/hotels/guawi-the-westin-camino-real-guatemala/overview/",
      approximateCost: "$180",
      estimatedTime: "15 a 20 minutos",
      note: "*El costo de la habitación depende del tipo que se elija.",
      includesBreakfast: false
    },
    {
      image: "/assets/images/hotels/hyatt.jpg",
      title: "Hyatt Centric Guatemala City",
      address: "12 Calle 2-25, Zona 10, Ciudad de Guatemala, 01010",
      link: "https://www.hyatt.com/hyatt-centric/es-ES/guact-hyatt-centric-guatemala-city",
      approximateCost: "$148",
      estimatedTime: "15 a 20 minutos",
      note: "*El costo de la habitación depende del tipo que se elija.",
      includesBreakfast: false
    },
    {
      image: "/assets/images/hotels/clarion.jpg",
      title: "Clarion Hotel & Suites",
      address: "14 Calle 3-08, Zona 10, Ciudad de Guatemala, 01010",
      link: "https://clarionguatemala.com/",
      approximateCost: "$130",
      estimatedTime: "15 a 20 minutos",
      note: "*El costo de la habitación depende del tipo que se elija.",
      includesBreakfast: false
    },
    {
      image: "/assets/images/hotels/holidayinn.jpg",
      title: "Holiday Inn Guatemala",
      address: "1 Avenida 13-22, Zona 10, Ciudad de Guatemala, 01010",
      link: "https://www.ihg.com/holidayinn/hotels/es/es/ciudad-de-guatemala/guact/hoteldetail",
      approximateCost: "$130",
      estimatedTime: "15 a 20 minutos",
      note: "*El costo de la habitación depende del tipo que se elija.",
      includesBreakfast: true
    },
    {
      image: "/assets/images/hotels/wydham.jpg",
      title: "Wydham Garden Guatemala City",
      address: "1 Avenida 12-46, Zona 10, Ciudad de Guatemala, 01010",
      link: "https://www.wyndhamhotels.com/es-xl/wyndham-garden/guatemala-guatemala/wyndham-garden-guatemala-city/overview",
      approximateCost: "$85",
      estimatedTime: "15 a 20 minutos",
      note: "*El costo de la habitación depende del tipo que se elija.",
      includesBreakfast: true
    },
    {
      image: "/assets/images/hotels/fontabella.jpg",
      title: "H&F Hotel Fontabella",
      address: "13 Calle 4-29, Zona 10, Ciudad de Guatemala, 01010",
      link: "https://hotelfontabella.com/",
      approximateCost: "$115",
      estimatedTime: "10 a 15 minutos",
      note: "*El costo de la habitación depende del tipo que se elija.",
      includesBreakfast: true
    },
    {
      image: "/assets/images/hotels/bestwestern.jpg",
      title: "Best Western Plus Hotel Stofella",
      address: "2 Avenida 12-28, Zona 10, Ciudad de Guatemala, 01010",
      link: "https://stofella.com/",
      approximateCost: "$100",
      estimatedTime: "15 a 20 minutos",
      note: "*El costo de la habitación depende del tipo que se elija.",
      includesBreakfast: true
    },
    {
      image: "/assets/images/hotels/veranda.jpg",
      title: "Hotel Casa Veranda",
      address: "12 Calle 1-24, Zona 10, Ciudad de Guatemala, 01010",
      link: "https://www.hotelcasaveranda.com/",
      approximateCost: "$85",
      estimatedTime: "15 a 20 minutos",
      note: "*El costo de la habitación depende del tipo que se elija.",
      includesBreakfast: false
    },
    {
      image: "/assets/images/hotels/meraki.jpg",
      title: "Meraki Boutique Hotel",
      address: "13 Calle 3-57, Zona 10, Ciudad de Guatemala, 01010",
      link: "https://www.booking.com/hotel/gt/meraki-boutique.es.html",
      approximateCost: "$80",
      estimatedTime: "15 a 20 minutos",
      note: "*El costo de la habitación depende del tipo que se elija.",
      includesBreakfast: true
    },
    {
      image: "/assets/images/hotels/ciudadvieja.jpg",
      title: "Hotel Ciudad Vieja",
      address: "8 Calle 3-67, Zona 10, Ciudad de Guatemala, 01010",
      link: "https://hotelciudadvieja.com/",
      approximateCost: "$70",
      estimatedTime: "15 a 20 minutos",
      note: "*El costo de la habitación depende del tipo que se elija.",
      includesBreakfast: false
    },
    {
      image: "/assets/images/hotels/quetzalroo.jpg",
      title: "Quetzalroo Hostel",
      address: "Avenida La Reforma 7-89, Zona 10, Ciudad de Guatemala, 01010",
      link: "http://www.quetzalroo.com/",
      approximateCost: "$10",
      estimatedTime: "15 a 20 minutos",
      note: "*El costo de la habitación depende del tipo que se elija.",
      includesBreakfast: false
    },
    {
      image: "/assets/images/hotels/central10.jpg",
      title: "Central 10 Boutique Hostel",
      address: "6 Avenida 14-49, Zona 10, Ciudad de Guatemala, 01010",
      link: "https://www.booking.com/hotel/gt/central-hostel-ciudad-de-guatemala.html",
      approximateCost: "$10",
      estimatedTime: "15 a 20 minutos",
      note: "*El costo de la habitación depende del tipo que se elija.",
      includesBreakfast: true
    },
    {
      image: "/assets/images/hotels/centralhostel.jpg",
      title: "Central Hostel Reforma",
      address: "14 Calle 0-61, Zona 10, Ciudad de Guatemala, 01010",
      link: "https://www.booking.com/hotel/gt/central-hostel-reforma.html",
      approximateCost: "$10",
      estimatedTime: "15 a 20 minutos",
      note: "*El costo de la habitación depende del tipo que se elija.",
      includesBreakfast: true
    }
  ],
  "Zona 9": [
    {
      image: "/assets/images/hotels/hilton.jpg",
      title: "Hilton Garden Inn",
      address: "13 Calle 7-65, Zona 9, Ciudad de Guatemala, 01009",
      link: "https://www.hilton.com/es/hotels/guagcgi-hilton-garden-inn-guatemala-city/",
      approximateCost: "$85",
      estimatedTime: "15 a 20 minutos",
      note: "*El costo de la habitación depende del tipo que se elija.",
      includesBreakfast: false
    },
    {
      image: "/assets/images/hotels/barcelo.jpg",
      title: "Barceló Guatemala City",
      address: "7 Avenida 15-45, Zona 9, Ciudad de Guatemala, 01009",
      link: "https://www.barcelo.com/es-us/barcelo-guatemala-city/",
      approximateCost: "$68",
      estimatedTime: "15 a 20 minutos",
      note: "*El costo de la habitación depende del tipo que se elija.",
      includesBreakfast: false
    },
    {
      image: "/assets/images/hotels/villaespañola.jpg",
      title: "Hotel Villa Española",
      address: "2 Calle 7-51, Zona 9, Ciudad de Guatemala, 01009",
      link: "https://www.hotelvillaespanola.com/",
      approximateCost: "$80",
      estimatedTime: "15 a 20 minutos",
      note: "*El costo de la habitación depende del tipo que se elija.",
      includesBreakfast: true
    },
  ],
  "Zona 4": [
    {
      image: "/assets/images/hotels/conquistador.jpg",
      title: "Conquistador Hotel & Conference Center",
      address: "Vía 5 4-68, Zona 4, Ciudad de Guatemala, 01004",
      link: "https://www.booking.com/hotel/gt/conquistador-amp-conference-center.html",
      approximateCost: "$70",
      estimatedTime: "20 a 25 minutos",
      note: "*El costo de la habitación depende del tipo que se elija.",
      includesBreakfast: false
    },
    {
      image: "/assets/images/hotels/goodhotel.jpg",
      title: "Good Hotel Guatemala City",
      address: "Ruta 4 6-32, Zona 4, Ciudad de Guatemala, 01004",
      link: "https://goodhotel.co/guatemala-city",
      approximateCost: "$95",
      estimatedTime: "15 a 20 minutos",
      note: "*El costo de la habitación depende del tipo que se elija.",
      includesBreakfast: false
    },
    {
      image: "/assets/images/hotels/riohostel.jpg",
      title: "Rio Hostel",
      address: "Ruta 1 4-48, Zona 4, Ciudad de Guatemala, 01004",
      link: "https://www.booking.com/hotel/gt/rio-hostel-ciudad-de-guatemala1.html",
      approximateCost: "$25",
      estimatedTime: "15 a 20 minutos",
      note: "*El costo de la habitación depende del tipo que se elija.",
      includesBreakfast: true
    }
  ]
};

export const HotelsContent = () => (
  <div style={{ padding: "20px" }}>
    {Object.keys(hotelData).map((zone) => (
      <div key={zone} style={{ marginBottom: "40px" }}>
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>{zone}</h2>
        <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
          {hotelData[zone].map((hotel, index) => (
            <HotelCard key={index} {...hotel} />
          ))}
        </div>
      </div>
    ))}
  </div>
);