import { Contact } from "../../component/Contact";
import Footer from "../../component/Footer/Footer";
import { Navbar } from "../../component/Navbar/NavBar";
import { HotelsContent } from "./Hotels.content";
import { useTranslation } from "react-i18next";
import { Row, Col } from "reactstrap";
import { HotelsPageContainer } from "./Hotels.stylesheets";

export const HotelsPage = () => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column vh-100">
      <Navbar fixedTop={false} />
      <HotelsPageContainer>
        <Row>
          <Col>
            <h3 className="justify-content-center text-center title">
              {t("ALOJAMIENTO EN LA CIUDAD DE GUATEMALA")}
            </h3>
          </Col>
        </Row>

        <Row>
          <Col>
            <p className="mt-5" align="justify">
              {t("Para facilitar su estancia durante el Congreso Espacial Centroamericano 2024, hemos seleccionado una variedad de hoteles en diferentes zonas de la Ciudad de Guatemala. Estos hoteles han sido cuidadosamente elegidos por su comodidad, cercanía a la sede del congreso y la calidad de sus servicios. Entre las opciones destacadas, encontrará hoteles de distintas categorías y precios, adaptados a diferentes necesidades y preferencias. Además, la ciudad ofrece una amplia variedad de alojamientos a través de plataformas como Airbnb, brindando opciones adicionales que pueden ajustarse a su presupuesto y preferencias personales. Le recomendamos revisar las siguientes sugerencias y elegir la opción que mejor se adapte a sus necesidades.")}
            </p>
            <p className="mt-3" align="justify">
              {t("Las zonas más cercanas al congreso son la Zona 15, donde se encuentra la UVG; la Zona 10, reconocida como la zona hotelera de la ciudad; la Zona 9 y la Zona 4, que es una de las zonas de mayor crecimiento en la Ciudad de Guatemala y que cuenta con una oferta cultural y gastronómica interesante y de calidad.")}
            </p>
          </Col>
        </Row>

        <HotelsContent />
      </HotelsPageContainer>
      <Contact />
      <Footer />
    </div>
  );
};
